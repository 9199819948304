/* src/Home.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

/* General Styles */
body {
  margin: 0;
  font-family: 'Nunito', sans-serif;


}

a {
  text-decoration: none;
  color: inherit;
}
.home{
  background-color: white;
}
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Navbar */
.navbar {
  background-color: #ffffff;
  padding: 15px 0;
  border-bottom: 1px solid #dfe6e9;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;

  justify-content: center;
}



.flat-navbar {


  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navbar-logo img {
  max-height: 50px; /* Consistent logo size */
}

.navbar-menu {
  list-style: none;
  display: flex;
  gap: 20px; /* Space between menu items */
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.navbar-menu li {
  display: inline;
}

.navbar-menu a {
  text-decoration: none;
  color: #333; /* Dark text for contrast */
  font-weight: 500;
  transition: color 0.3s;
}

.navbar-menu a:hover {
  color: #007bff; /* Highlight color on hover */
}

.navbar-btn {
  background-color: #007bff; /* Primary button color */
  color: #fff;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.navbar-btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.btn {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
}

.btn-primary {
  background-color: #0984e3;
  color: #ffffff;
  border-radius: 25px;
  font-weight: 600;
  padding: 10px 20px;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #74b9ff;
}

.btn-secondary {
  background-color: #fdcb6e;
  color: #2d3436;
  border-radius: 25px;
  font-weight: 600;
  padding: 10px 20px;
  font-size: 1em;
  transition: background-color 0.3s ease;

}

.btn-secondary:hover {
  background-color: #ffeaa7;
}

.btn-lg {
  padding: 15px 30px;
  font-size: 1.1em;
  border-radius: 30px;
}


.btn:hover {
  text-decoration: none;
}

/* Hero Section */
.hero {
  
  background-size: cover;
  background-position: center;

  padding: 160px 0 120px;
  text-align: center;
  position: relative;
}

.hero::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #c0d8ff;

}

.hero .container {
  position: relative;
  z-index: 1;
}

.hero-title {
  font-size: 2.5em;
  margin-bottom: 20px;
  animation: fadeInDown 1s ease-in-out;
  color:'black';
}

.hero-subtitle {
  font-size: 1.3em;
  margin-bottom: 40px;
  animation: fadeInUp 1s ease-in-out 0.5s;
  animation-fill-mode: forwards;
}

.animate-bounce {
  animation: bounce 2s infinite;
}

.media-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  opacity: 0;
  animation: fadeInUp 1s ease-in-out 1s forwards;
}

.media-logos img {
  margin: 15px;
  height: 50px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.media-logos img:hover {
  opacity: 1;
}

/* Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7px);
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-fade-in {
  animation: fadeInDown 1s ease-in-out;
}

.animate-fade-in-delay {
  animation: fadeInUp 1s ease-in-out 0.5s;
  animation-fill-mode: forwards;
}

.animate-slide-up {
  animation: fadeInUp 1s ease-in-out 1s;
  animation-fill-mode: forwards;
}

.animate-zoom-in {
  animation: zoomIn 0.8s ease-in-out;
}

/* Case Studies */
.case-studies {
  padding: 80px 0;
  background-color: #ffffff;
}

.section-title {
  font-size: 2em;
  text-align: center;
  margin-bottom: 10px;
  color: #2d3436;
}

.section-subtitle {
  font-size: 1.1em;
  text-align: center;
  margin-bottom: 50px;
  color: #636e72;
}

.case-studies-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.case-study-item {
  background-color: #f5f6fa;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  flex: 1 1 300px;
  max-width: 350px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.case-study-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.case-study-item img {
  height: 80px;
  margin-bottom: 20px;
}

.case-study-item h3 {
  font-size: 1.3em;
  margin-bottom: 15px;
  color: #0984e3;
}

.case-study-item p {
  margin-bottom: 15px;
  font-size: 0.95em;
  color: #636e72;
}

.case-study-item strong {
  color: #2d3436;
}

.cta {
  text-align: center;
  margin-top: 60px;
}

/* About Section */
.about {
  padding: 80px 0;
  background-color: #c0d8ff;
}

.about h2 {
  font-size: 2em;
  margin-bottom: 30px;
  text-align: center;
  color: #2d3436;
}

.about p {
  font-size: 1.1em;
  margin-bottom: 25px;
  line-height: 1.6;
  text-align: center;
  color: #636e72;
}

.about .btn {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 25px;
}

/* Footer */
.footer {

  color: #dfe6e9;

  text-align: center;
  margin-top:5vh;

}

.footer a {
  color: black;
}

.footer a:hover {
  color: #b2bec3;
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar-menu {
    flex-direction: column;
    align-items: flex-start;
    display: none;
  }

  .navbar-menu li {
    margin: 10px 0;
  }

  .navbar-toggle {
    display: none;
  }

  .navbar-icon {
    display: block;
    position: absolute;
    top: 22px;
    right: 20px;
    cursor: pointer;
  }

  .navbar-icon span,
  .navbar-icon span::before,
  .navbar-icon span::after {
    display: block;
    background-color: #2d3436;
    height: 3px;
    width: 25px;
    position: relative;
    transition: all 0.3s ease;
  }

  .navbar-icon span::before,
  .navbar-icon span::after {
    content: '';
    position: absolute;
  }

  .navbar-icon span::before {
    top: -8px;
  }

  .navbar-icon span::after {
    top: 8px;
  }

  #navbar-toggle:checked + .navbar-icon span {
    background: transparent;
  }

  #navbar-toggle:checked + .navbar-icon span::before {
    transform: rotate(45deg);
    top: 0;
  }

  #navbar-toggle:checked + .navbar-icon span::after {
    transform: rotate(-45deg);
    top: 0;
  }

  #navbar-toggle:checked ~ .navbar-menu {
    display: flex;
  }

  .hero {
    padding: 120px 0 100px;
  }

  .hero-title {
    font-size: 1.8em;
  }

  .hero-subtitle {
    font-size: 1em;
  }

  .case-study-item {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .about h2 {
    font-size: 1.8em;
  }

  .btn-lg {
    padding: 12px 25px;
    font-size: 1em;
  }
}
